<template>
  <div class="my-page-investor-apply my-page" :class="{skeleton : !state.loaded }">
    <div class="top">
      <div class="alert" :class="[$store.state.investor.investorAuth === 'INVESTOR_STATUS_APPLY' ? 'alert-info' : 'alert-warning']">
        <div class="font-sm">
          <span>{{ computedStatusMessage }}</span>
        </div>
        <div class="pt-2 font-sm" v-if="$store.state.investor.investorAuth === 'N'">
          <span>{{ $store.state.account.memberType === "C" ? "사업자등록증" : "신분증" }} 인증과 출금 계좌 등록 후 증권형 투자가 가능하오니 아래의 내용을 기입해주세요.</span>
        </div>
        <div class="pt-2 font-sm" v-if="state.eKYCAuthFlag === 'N'">
          <span>{{ $store.state.account.memberType === "C" ? "사업자등록증" : "신분증" }} 인증은 1시간 내에 30회까지만 요청할 수 있습니다.</span>
        </div>
        <div class="pt-2 font-sm" v-if="state.eKYCAuthFlag === 'N'">
          <span>조합고유번호, 외국인(투자등록번호) 처리는 최소 1영업일이 소요됩니다. 더 빠른 처리를 원하실 경우 오마이컴퍼니로 연락주시기 바랍니다.</span>
        </div>
      </div>
      <div class="alert alert-secondary" v-if="$store.state.investor.investorAuth === 'INVESTOR_STATUS_DENY'">
        <div class="font-sm">
          <span>{{ $store.state.investor.investorReason || "입력 정보를 다시 확인하시고 신청해주시기 바랍니다." }}</span>
        </div>
      </div>
    </div>
    <!-- 투자자 정보 -->
    <div v-show="state.step === 1">
      <div class="part sms">
        <div class="subject">
          <b>투자자 정보</b>
        </div>
        <div class="content">
          <div class="alert alert-danger font-sm" v-if="state.investorName.alertMessage">
            <span>{{ state.investorName.alertMessage }}</span>
          </div>
          <template v-if="$store.state.account.memberType === 'C'">
            <div class="row">
              <div class="form-group col-md-6">
                <div class="wrapper">
                  <label :for="`${component.name}InvestorName`" class="subject font-sm">
                    <span>기업명</span>
                  </label>
                  <input :id="`${component.name}InvestorName`" type="text" class="form-control border-f ocus-point font-sm" :value="state.form.investorName" disabled/>
                </div>
              </div>
              <div class="form-group col-md-6">
                <div class="wrapper">
                  <label class="subject font-sm">
                    <span>담당자 이메일</span>
                  </label>
                  <input type="text" class="form-control border-focus-point font-sm" v-model="state.form.email" disabled/>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-6">
                <div class="wrapper">
                  <label :for="`${component.name}CeoName`" class="subject font-sm">
                    <span>대표자명</span>
                  </label>
                  <input :id="`${component.name}CeoName`" type="text" class="form-control border-f ocus-point font-sm" :value="state.form.ceoName" disabled/>
                </div>
              </div>
              <div class="form-group col-md-6">
                <div class="wrapper">
                  <label :for="`${component.name}InvestorTelBtn`" class="subject font-sm">
                    <span>대표자 휴대전화</span>
                  </label>
                  <button :id="`${component.name}InvestorTelBtn`" class="btn btn-block btn-default font-sm" @click="openNiceAuth()" :disabled="computedInapplicable" v-if="state.niceAuthFlag === 'N'">
                    {{ state.niceAuthFlag === "Y" ? "인증 완료" : "본인 인증" }}
                  </button>
                  <Phone :id="`${component.name}InvestorTel`" :value.sync="state.form.tel" placeholder="ex) 01012345678" disabled v-else/>
                  <div class="side" v-if="state.form.token && !computedInapplicable">
                    <a class="color-anchor pointer-u font-xs" @click="resetNiceAuth()">재인증</a>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="row">
              <div class="form-group col-md-6 investor-name">
                <div class="wrapper">
                  <label :for="`${component.name}InvestorName`" class="subject font-sm">
                    <span>이름</span>
                  </label>
                  <div class="change" :class="{changeable: !computedInapplicable}">
                    <input :id="`${component.name}InvestorName`" @keyup.enter="changeInvestorName(false)" type="text" class="form-control border-focus-point font-sm"
                           :value="state.form.investorName" autocomplete="off" :disabled="!state.investorName.changeable"/>
                    <button class="btn" :class="[state.investorName.changeable ? 'btn-point' : 'btn-secondary']" @click="changeInvestorName(!state.investorName.changeable)"
                            v-if="!state.form.token">{{ state.investorName.changeable ? "완료" : "수정" }}
                    </button>
                  </div>
                </div>
              </div>
              <div class="form-group col-md-6 investor-tel">
                <div class="wrapper">
                  <label :for="`${component.name}InvestorTelBtn`" class="subject font-sm">
                    <span>휴대전화</span>
                  </label>
                  <div class="input-group font-sm">
                    <button :id="`${component.name}InvestorTelBtn`" class="btn btn-block btn-default font-sm" @click="openNiceAuth()" :disabled="computedInapplicable" v-if="state.niceAuthFlag === 'N'">
                      {{ state.niceAuthFlag === "Y" ? "인증 완료" : "본인 인증" }}
                    </button>
                    <Phone :id="`${component.name}InvestorTel`" :value.sync="state.form.tel" placeholder="ex) 01012345678" disabled v-else/>
                  </div>
                  <div class="side" v-if="state.form.token && !computedInapplicable">
                    <a class="color-anchor pointer-u font-xs" @click="resetNiceAuth()">재인증</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col">
                <div class="wrapper">
                  <label class="subject font-sm">
                    <span>이메일</span>
                  </label>
                  <input type="text" class="form-control border-focus-point font-sm" v-model="state.form.email" disabled/>
                </div>
              </div>
            </div>
          </template>
          <div class="row address">
            <div class="form-group col">
              <div class="wrapper">
                <label class="subject font-sm" :for="`${component.name}InvestorAddress`">
                  <span v-if="$store.state.account.memberType === 'C'">기업 </span>
                  <span>주소</span>
                </label>
                <Address :id="`${component.name}InvestorAddress`" :zoneCode.sync="state.form.postcode" :address1.sync="state.form.address" :address2.sync="state.form.addressDetail"
                         :disabled="['INVESTOR_STATUS_APPLY', 'INVESTOR_STATUS_STANDBY'].includes($store.state.investor.investorAuth)"/>
              </div>
            </div>
          </div>
          <template v-if="$store.state.account.memberType === 'C'">
            <div class="row">
              <div class="form-group col-md-6">
                <div class="wrapper">
                  <label :for="`${component.name}Realnumber`" class="subject font-sm">
                    <span>법인등록번호</span>
                  </label>
                  <input :id="`${component.name}Realnumber`" type="text" class="form-control border-focus-point font-sm" :value="state.form.realnumber" disabled/>
                </div>
              </div>
              <div class="form-group col-md-6">
                <div class="wrapper">
                  <label :for="`${component.name}Buznumber`" class="subject font-sm">
                    <span>사업자등록번호</span>
                  </label>
                  <input :id="`${component.name}Buznumber`" type="text" class="form-control border-focus-point font-sm" :value="state.form.buznumber" disabled/>
                </div>
              </div>
            </div>
            <div class="alert alert-info font-sm">
              <div>
                <span>투자자 인증을 위해 투자자 정보가 실제 정보와 일치해야 합니다.</span>
              </div>
              <div class="pt-2">
              <span>
                <span>투자자 정보 수정을 원하시면&nbsp;</span>
                <router-link to="/mypage/account/company" class="underline">마이페이지 - 계정 정보</router-link>
                <span>&nbsp;페이지의 내용을 입력해주세요.</span>
              </span>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <!-- 출금 계좌 등록 -->
    <div v-show="state.step === 2">
      <div class="part investor">
        <div class="subject">
          <b>출금 계좌 등록</b>
        </div>
        <div class="content">
          <BankBookWithdraw :withdrawAccountCode.sync="state.form.withdrawAccountCode" :withdrawAccountNum.sync="state.form.withdrawAccountNum"
                            :items="!['INVESTOR_STATUS_APPLY', 'INVESTOR_STATUS_STANDBY'].includes($store.state.investor.investorAuth) ? ['change'] : []"/>
        </div>
      </div>
      <ul class="guide bottom tight font-sm">
        <li>증권형 펀딩에 투자하기 위해서는 출금 계좌 등록 및 인증이 필요합니다.</li>
        <li>출금 계좌를 등록하면 투자금을 예치하는 OMC 계좌도 자동 개설됩니다.</li>
        <li>출금 계좌는 투자계좌 정보에서 변경 가능합니다.</li>
      </ul>
    </div>
    <!-- 신분증(사업자등록증) 확인 -->
    <div v-show="state.step === 3">
      <div class="part cert">
        <div class="subject">
          <b>{{ $store.state.account.memberType === "C" ? "사업자등록증 인증" : "신분증 인증" }}</b>
        </div>
        <div class="content">
          <div class="row" v-if="state.eKYCAuthFlag === 'N'">
            <div class="form-group col">
              <div class="wrapper">
                <label :for="`${component.name}RealnumberCerttypeCode`" class="subject font-sm">
                  <span>실명 인증 유형</span>
                </label>
                <select :id="`${component.name}RealnumberCerttypeCode`" class="form-control border-focus-point font-sm" v-model="state.form.realnumberCerttypeCode"
                        :disabled="state.eKYCAuthFlag === 'Y' || ['INVESTOR_STATUS_APPLY', 'INVESTOR_STATUS_STANDBY'].includes($store.state.investor.investorAuth)">
                  <template v-if="$store.state.account.memberType === 'C'">
                    <option value="12">법인등록번호</option>
                    <option value="13">조합고유번호</option>
                  </template>
                  <template v-else>
                    <option value="11">주민등록번호</option>
                    <option value="22">외국인(투자등록번호)</option>
                    <option value="23">외국인등록번호</option>
                  </template>
                </select>
              </div>
            </div>
          </div>
          <div class="registration" v-if="$store.state.account.memberType !== 'C' && state.eKYCAuthFlag === 'N'">
            <label :for="`${component.name}RealNum1`" class="subject font-sm">
              <span>주민등록번호</span>
              <span class="font-xs"> (외국인등록번호)</span>
            </label>
            <div class="row">
              <div class="form-group col">
                <div class="wrapper">
                  <input :id="`${component.name}RealNum1`" type="text" class="form-control border-focus-point font-sm" ref="realNum1Ref"
                         maxlength="6" :disabled="state.eKYCAuthFlag === 'Y' || ['INVESTOR_STATUS_APPLY', 'INVESTOR_STATUS_STANDBY'].includes($store.state.investor.investorAuth)"/>
                </div>
              </div>
              <div class="form-group col">
                <div class="wrapper">
                  <input type="text" class="form-control border-focus-point font-sm" ref="realNum2Ref"
                         maxlength="7" :disabled="state.eKYCAuthFlag === 'Y' || ['INVESTOR_STATUS_APPLY', 'INVESTOR_STATUS_STANDBY'].includes($store.state.investor.investorAuth)"/>
                </div>
              </div>
            </div>
          </div>
          <div class="alert alert-info font-sm mb-0" v-if="state.eKYCAuthFlag === 'Y'">
            <label :for="`${component.name}File`" class="subject font-sm mb-0">
              <div>{{ $store.state.account.memberType === "C" ? "사업자등록증" : "신분증" }}이 정상적으로 인증되었습니다. 다음으로 진행해주세요.</div>
            </label>
          </div>
          <div class="file" v-else>
            <label :for="`${component.name}File`" class="subject font-sm">
              <template v-if="$store.state.account.memberType === 'C'">
                <span>사업자등록증</span>
              </template>
              <template v-else>
                <span>신분증 사본</span>
                <span class="font-xs"> (주민등록증, 운전면허증, 외국인등록증)</span>
              </template>
              <span class="font-xs color-secondary"> *jpg, jpeg, png 이미지 / pdf, tiff 단일 페이지 형식 지원</span>
            </label>
            <div class="alert alert-info" v-if="state.eKYCAuthFlag === 'N'">
              <div class="font-sm">
                <span>
                  - 신분증을 어두운 배경 위에 놓으시고 촬영해주세요.<br>
                  - 신분증의 표면에 빛 반사가 없도록 촬영해주세요.<br>
                  - 신분증의 배경에 문자가 없도록 촬영해주세요.<br>
                  - 신분증의 발급일자가 식별 가능하도록 촬영해주세요.<br>
                  - 운전면허증의 경우, 오른쪽 하단의 암호일련번호 6자리가 잘 보이게 촬영해주세요.<br>
                  ※ 운전면허증이 인식되지 않으면 주민등록증을 촬영해주세요.<br>
                  - 신분증 인식이 계속 실패할 경우, 관리자에게 문의해주세요.(070-5097-2231)<br>
                </span>
              </div>
            </div>
            <div class="alert alert-danger" v-if="state.alert !== ''">
              <div class="font-sm">
                <span>
                  {{ state.alert }}
                </span>
              </div>
            </div>
            <div class="row">
              <div class="form-group col">
                <div class="wrapper">
                  <ul class="tight font-sm" v-if="state.files.length">
                    <li v-for="(f, idx) in state.files" :key="idx">
                      <a :href="`${f.filePath}${f.fileSaveName.startsWith('/') ? f.fileSaveName.substring(1) : f.fileSaveName}`" target="_blank" class="no-underline" rel="noopener noreferrer">{{ f.fileOrigName }}</a>
                      <i class="fa fa-close" title="파일 삭제" @click="removeFile(f.fileSeq)" v-if="!['INVESTOR_STATUS_APPLY', 'INVESTOR_STATUS_STANDBY'].includes($store.state.investor.investorAuth)"></i>
                    </li>
                  </ul>
                  <input :id="`${component.name}File`" type="file" :accept="$definitions.limits.fileExtensions.allStr" class="form-control border-focus-point font-sm" ref="fileRef"
                         :disabled="['INVESTOR_STATUS_APPLY', 'INVESTOR_STATUS_STANDBY'].includes($store.state.investor.investorAuth)" @change="setFile($event)"
                         v-else/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 약관 동의 -->
    <div v-show="state.step === 4">
      <div class="part terms">
        <div class="subject">
          <b>이용약관 & 개인정보처리방침</b>
        </div>
        <div class="checklist">
          <ul class="guide bottom tight font-sm">
            <li>투자자 등록을 위해 이용약관, 개인정보처리방침에 동의해주세요.</li>
            <li>조합고유번호, 외국인(투자등록번호) 처리는 최소 1영업일이 소요됩니다. 더 빠른 처리를 원하실 경우 오마이컴퍼니로 연락주시기 바랍니다.<br>(<a href="tel:023882556">02-388-2556</a>, 평일 9~18시)</li>
          </ul>
          <ul class="tight font-sm">
            <li>
              <label>
                <input type="checkbox" ref="applyCheck1Ref" :checked="['INVESTOR_STATUS_APPLY', 'INVESTOR_STATUS_STANDBY'].includes($store.state.investor.investorAuth)"
                       :disabled="['INVESTOR_STATUS_APPLY', 'INVESTOR_STATUS_STANDBY'].includes($store.state.investor.investorAuth)">
                <span class="text">
                  <u class="pointer" @click.prevent="$store.commit('openModal', { name: 'Terms', params: { name: 'investTerms' } })">증권형 이용약관</u>
                  <span>에 동의합니다.</span>
                </span>
              </label>
            </li>
            <li>
              <label>
                <input type="checkbox" ref="applyCheck2Ref" :checked="['INVESTOR_STATUS_APPLY', 'INVESTOR_STATUS_STANDBY'].includes($store.state.investor.investorAuth)"
                       :disabled="['INVESTOR_STATUS_APPLY', 'INVESTOR_STATUS_STANDBY'].includes($store.state.investor.investorAuth)">
                <span class="text">
                  <u class="pointer" @click.prevent="$store.commit('openModal', { name: 'Terms', params: { name: 'investPrivacy' } })">증권형 개인정보처리방침</u>
                  <span>에 동의합니다.</span>
                </span>
              </label>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="actions">
      <a class="prev pointer btn btn-light font-sm" v-if="state.step > 1" @click="previousStep()">이전으로</a>
      <template v-if="state.step === 3">
        <a class="btn btn-point font-sm" v-if="['11', '23', '12'].includes(state.form.realnumberCerttypeCode) && state.eKYCAuthFlag === 'N'" @click="documentVerify()">{{ $store.state.account.memberType === "C" ? "사업자등록증" : "신분증" }} 인증하기</a>
        <a class="btn btn-point font-sm" v-else @click="nextStep()">다음으로</a>
      </template>
      <template v-else>
        <a class="btn btn-point font-sm" v-if="state.step !== 4" @click="nextStep()">다음으로</a>
        <button class="btn btn-point font-sm" v-else-if="!['INVESTOR_STATUS_APPLY'].includes($store.state.investor.investorAuth)" @click="submit()">
          {{
            $store.state.investor.investorAuth === "INVESTOR_STATUS_DENY" ?
                "재인증 신청하기" : ["11", "23", "12"].includes(state.form.realnumberCerttypeCode) ?
                    "투자자 등록하기" : "인증 신청하기"
          }}
        </button>
      </template>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, nextTick, onUnmounted, reactive, ref} from "@vue/composition-api";
import http from "@/scripts/http";
import lib from "@/scripts/lib";
import Address from "@/components/Address";
import store from "@/scripts/store";
import Phone from "@/components/Phone";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";
import BankBookWithdraw from "@/components/BankBookWithdraw";
import track from "@/scripts/track";
import router from "@/scripts/router";
import {FormDataCustom} from "@/scripts/FormDataCustom";

function Component(initialize) {
  this.name = "pageMyPageInvestorApply";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {BankBookWithdraw, Phone, Address},
  props: {
    menu: Object
  },
  setup() {
    const component = new Component(async () => {
      await store.dispatch("setDocumentTitle", "투자자 인증");

      if (!await store.getters.investProjectAvailable("myPage")) {
        return store.commit("openModal", {
          name: "InvestProjectPauseNotice",
          routerReplace: true,
          preventClose: true
        });
      }

      http.get("/api/investor/auth-module").then(({data}) => {
        state.encodeData = data.body.sEncData;
      });

      // 투자자 SEQ
      state.investorSeq = store.state.investor.investorSeq;

      if (!state.investorSeq) {
        const res = await http.post("/api/investors").catch(httpError());
        state.investorSeq = res.data.body;
      }

      state.form.memberType = store.state.account.memberType;
      state.niceAuthFlag = "N"; // 처음 신청이나 재신청이나 모두 NICE 인증 받도록 처리

      load();
    });

    const state = reactive({
      loaded: false,
      investorSeq: "",
      niceAuthFlag: "Y",
      encodeData: "",
      files: [],
      message: "",
      alert: "",
      investorName: {
        changeable: false,
        alertMessage: "",
      },
      form: {
        investorName: "",
        memberSeq: store.state.account.memberSeq,
        buznumber: "",
        realnumber: "",
        realnumberCerttypeCode: "",
        email: store.state.account.email,
        memberType: "",
        postcode: "",
        address: "",
        addressDetail: "",
        tel: "",
        token: "", // 나이스 본인 인증 완료 후 서버에서 생성한 토큰
        withdrawAccountCode: "",
        withdrawAccountNum: "",
        ceoName: "", // 법인일 때
      },
      step: 1,
      eKYCAuthFlag: "N",
    });

    const realNum1Ref = ref();
    const realNum2Ref = ref();
    const fileRef = ref();
    const applyCheck1Ref = ref();
    const applyCheck2Ref = ref();

    const computedInapplicable = computed(() => {
      return ["INVESTOR_STATUS_APPLY", "INVESTOR_STATUS_STANDBY"].includes(store.state.investor.investorAuth);
    });

    const computedStatusMessage = computed(() => {
      const messageArr = ["현재 "];

      switch (store.state.investor.investorAuth) {
        case "INVESTOR_STATUS_APPLY":
          messageArr.push("투자자 승인 대기 상태입니다. 최소 1영업일이 소요됩니다.");
          break;

        case "INVESTOR_STATUS_DENY":
          messageArr.push("투자자 승인 거절 상태입니다.");
          break;

        case "INVESTOR_STATUS_STANDBY":
          messageArr.push("투자자 승인 보류 상태입니다.");
          break;

        default:
          messageArr.push("투자자 미인증 상태입니다.");
          break;
      }

      return messageArr.join("");
    });

    const changeInvestorName = (changeable) => {
      const $elem = document.getElementById(`${component.name}InvestorName`);

      if (!$elem) {
        window.alert("오류가 있습니다. 관리자에게 문의해주세요.");
        return;
      }

      state.investorName.changeable = changeable !== undefined ? changeable : !state.investorName.changeable;

      if (!state.investorName.changeable) {
        state.form.investorName = $elem.value;
        window.alert("투자자 이름 입력이 완료되었습니다. 본인 인증을 클릭해주세요.");
        document.getElementById(`${component.name}InvestorTelBtn`)?.focus();
      } else {
        nextTick(() => {
          $elem.focus();
        });
      }
    };

    const submit = async () => {
      if (state.niceAuthFlag === "N") {
        state.step = 1;
        document.getElementById(`${component.name}InvestorTelBtn`)?.focus();
        return store.commit("setSwingMessage", (store.state.account.memberType === "C" ? "대표자 " : "") + "휴대전화 본인 인증을 진행해주세요.");
      }

      if (["11", "23", "12"].includes(state.form.realnumberCerttypeCode) && state.eKYCAuthFlag === "N") {
        document.getElementById(`${component.name}realNum1`)?.focus();
        return store.commit("setSwingMessage", store.state.account.memberType === "C" ? "사업자등록증 " : "신분증 " + "인증을 진행해주세요.");
      }

      const args = lib.getRenewed(state.form);

      // 법인
      if (["13", "22"].includes(state.form.realnumberCerttypeCode)) {
        if (store.state.account.memberType === "C") {
          state.step = 1;

          if (!args.postcode || !args.address || !args.addressDetail) {
            document.getElementById(`${component.name}InvestorAddress`)?.focus();
            return store.commit("setSwingMessage", "주소를 입력해주세요.");
          } else if (!state.files.length && !fileRef.value.value && state.eKYCAuthFlag === "N") {
            document.getElementById(`${component.name}File`)?.focus();
            return store.commit("setSwingMessage", "사업자등록증 파일을 첨부해주세요.");
          } else if (!state.form.realnumber && state.eKYCAuthFlag === "N") {
            return store.commit("setSwingMessage", "법인등록번호를 입력해주세요.");
          } else if (!state.form.buznumber && state.eKYCAuthFlag === "N") {
            return store.commit("setSwingMessage", "사업자등록번호를 입력해주세요.");
          }
        } else {
          const realNum1 = realNum1Ref.value.value;
          const realNum2 = realNum2Ref.value.value;

          if (!args.postcode || !args.address || !args.addressDetail) {
            state.step = 3;
            document.getElementById(`${component.name}InvestorAddress`)?.focus();
            return store.commit("setSwingMessage", "주소를 입력해주세요.");
          } else if (!realNum1 && state.eKYCAuthFlag === "N") {
            state.step = 3;
            realNum1Ref.value.focus();
            return store.commit("setSwingMessage", "주민등록번호(혹은 외국인등록번호)를 입력해주세요.");
          } else if (!realNum2 && state.eKYCAuthFlag === "N") {
            state.step = 3;
            realNum2Ref.value.focus();
            return store.commit("setSwingMessage", "주민등록번호(혹은 외국인등록번호)를 입력해주세요.");
          } else if (!state.files.length && !fileRef.value.value && state.eKYCAuthFlag === "N") {
            state.step = 3;
            document.getElementById(`${component.name}File`)?.focus();
            return store.commit("setSwingMessage", "신분증 사본 파일을 첨부해주세요.");
          }

          args.realnumber = [realNum1, realNum2].join("");
        }
      }

      if (!args.withdrawAccountCode || !args.withdrawAccountNum) {
        state.step = 2;
        return store.commit("setSwingMessage", "출금 계좌를 등록해주세요.");
      } else if (!applyCheck1Ref.value.checked) {
        applyCheck1Ref.value.focus();
        return store.commit("setSwingMessage", "이용약관에 동의하셔야 신청이 가능합니다.");
      } else if (!applyCheck2Ref.value.checked) {
        applyCheck2Ref.value.focus();
        return store.commit("setSwingMessage", "개인정보처리방침에 동의하셔야 신청이 가능합니다.");
      }

      let urlPath;
      let httpMethod;

      // 투자자 인증 OR 투자자 인증 요청
      if (["11", "23", "12"].includes(state.form.realnumberCerttypeCode)) {
        http.put(`/api/investors/${state.investorSeq}/apply`, args).then((res) => {
          if (res.data.body === "200 OK") {
            store.commit("setSwingMessage", "정상적으로 투자자 인증되었습니다.");
            store.state.investor.investorSeq = state.investorSeq;
            store.state.investor.investorAuth = "INVESTOR_STATUS_COMPLETE";
            router.push({path: "/mypage/investor-account-info"});
          }
        });
      } else {
        // 파일 저장
        if (state.files.length > 0) {
          const formData = new FormDataCustom({
            "file": fileRef.value.files[0]
          });

          const res = await http.post(`/api/investors/${state.investorSeq}/files`, formData).catch(httpError());

          if (res.error) {
            return;
          }

          state.files = [res.data.body];
        }

        //투자자 인증 요청
        urlPath = `/api/investors/${state.investorSeq}/auth`;
        httpMethod = store.state.investor.investorAuth === "INVESTOR_STATUS_DENY" ? "PUT" : "POST";
        const request = store.state.investor.investorAuth === "INVESTOR_STATUS_DENY" ? (http.put(urlPath, args)) : (http.post(urlPath, args));
        request.then(() => {
          store.commit("setSwingMessage", store.state.investor.investorAuth === "INVESTOR_STATUS_DENY" ? "재인증 신청을 완료하였습니다." : "인증 신청을 완료하였습니다.");
          store.commit("refresh");

          track.post({
            name: "myPageInvestorApply",
            category: "마이페이지",
            topic: "투자자 신청 완료",
            urlPath: urlPath,
            type: "api",
            httpMethod: httpMethod,
          });
        });
      }
    };

    const openNiceAuth = () => {
      if (state.investorName.changeable) {
        window.alert("이름 수정 후 완료 버튼을 클릭해주세요.");

        nextTick(() => {
          document.getElementById(`${component.name}InvestorName`)?.focus();
        });
        return;
      }

      const ua = navigator.userAgent.toLowerCase();

      const isNaverApp = /naver/.test(ua);

      let popUrl = "about:blank";

      // 안드로이드이면서 네이버 인앱브라우저인 경우 휴대폰 본인인증 팝업 데이터 넘어가지 않는 부분 조치
      if (lib.isAndroidMobile() && isNaverApp) {
        popUrl = "";
      } else if (lib.isAppleMobile() && lib.isOmcApp()) {
        // @todo 추후 처리
        // var form = document.getElementsByName("form_chk")[0];
        // var formData = new FormData(form);
        // var urlSearchParams = new URLSearchParams(formData);
        // var params = urlSearchParams.toString();
        // var niceUrl = "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
        // popUrl = niceUrl + "?" + params;
      }

      const popupName = "popupChk";
      const nicePopupWindow = window.open(popUrl, popupName, "width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no");

      if (!lib.isOmcApp() || !lib.isAppleMobile()) {
        if (nicePopupWindow == null) {
          return store.commit("setSwingMessage", "팝업 차단기능 혹은 팝업 차단 프로그램이 동작 중입니다. 팝업 차단을 해제하신 후 다시 시도해주세요.");
        }

        const args = {
          EncodeData: state.encodeData,
          m: "checkplusSerivce",
          param_r1: "investor", // 매개변수1(인증 구분 값)
        };

        const form = document.createElement("form");
        form.action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
        form.method = "post";
        form.target = popupName;

        for (let i in args) {
          const input = document.createElement("input");
          input.type = "hidden";
          input.name = i;
          input.value = args[i];
          form.append(input);
        }

        document.body.append(form);

        window.niceCertCallback = (res) => {
          state.investorName.alertMessage = "";

          if (res?.code === "0") {
            // 법인일 때
            if (store.state.account.memberType === "C") {
              if (state.form.ceoName !== res.name) {
                window.alert(`회원의 계정 정보(${state.form.ceoName})가 인증 받으신 정보(${res.name})와 다릅니다. 마이페이지 - 계정 정보의 내용을 확인해주세요.`);
                router.push("/mypage/account/company").then();
                return;
              }

              state.form.ceoName = res.name;
            } else {
              if (state.form.investorName !== res.name) {
                const message = `회원의 계정 정보(${state.form.investorName})가 인증 받으신 정보(${res.name})와 다릅니다. 이름을 확인해주세요.`;
                window.alert(message);

                nextTick(() => {
                  state.investorName.alertMessage = message;
                  changeInvestorName(true);
                });
                return;
              }

              state.form.investorName = res.name;
            }

            state.form.tel = res.mobile;
            state.form.token = res.token;
            state.niceAuthFlag = "Y";
          }

          store.commit("setSwingMessage", res.message);
        };

        form.submit();
        form.remove();
      }
    };

    const resetNiceAuth = () => {
      state.niceAuthFlag = "N";
      state.form.token = "";
    };

    const load = () => {
      state.loaded = false;

      http.get(`/api/members/${store.state.account.memberSeq}/account`).then(({data: memberData}) => {
        const {body: member} = memberData;

        http.get(`/api/investor/${state.investorSeq}`).then(({data: investorData}) => {
          const {body: investor} = investorData;
          state.form.investorName = member.memberName;
          state.form.tel = investor.investor.investorTel || (member.mobile ? member.mobile : "");
          state.form.address = investor.investor.investorAddr1 || member.memberAddr1;
          state.form.addressDetail = investor.investor.investorAddr2 || member.memberAddr2;
          state.form.postcode = investor.investor.investorPost || member.memberPost;
          state.form.email = investor.investor.email || member.email;
          state.form.realnumberCerttypeCode = investor.investor.realnumberCerttypeCode || (store.state.account.memberType === "C" ? "12" : "11");

          if (store.state.account.memberType === "C") {
            state.form.investorName = investor.investor.corporateName;
            state.form.realnumber = investor.investor.realnumber || member.corporateNum;
            state.form.buznumber = investor.investor.businessNum || member.businessNum;
            state.form.ceoName = investor.investor.ceoName || member.ceoName;
          } else if (investor.investor.realnumber?.length === 13) {
            realNum1Ref.value.value = investor.investor.realnumber.substring(0, 6);
            realNum2Ref.value.value = investor.investor.realnumber.substring(6);
          }

          state.eKYCAuthFlag = investor.ekycAuthFlag;
          state.loaded = true;
          state.files = investor.fileList;
        });
      });
    };

    const setFile = (e) => {
      return store.getters.isAllowedExtension(e.target, "eKYC");
    };

    const removeFile = (fileSeq) => {
      store.commit("confirm", {
        message: "선택하신 파일을 삭제하시겠습니까?",
        allow() {
          http.delete(`/api/investor/${state.investorSeq}/file/${fileSeq}`).then(() => {
            const idx = state.files.findIndex(f => f.fileSeq === fileSeq);
            idx >= 0 && state.files.splice(idx);
            store.commit("setSwingMessage", "선택하신 파일을 삭제하였습니다.");
          }).catch(httpError());
        }
      });
    };

    onUnmounted(() => {
      if (window.niceCertCallback !== undefined) {
        delete window.niceCertCallback;
      }
    });

    const documentVerify = async () => {
      state.alert = "";

      if (state.niceAuthFlag === "N") {
        state.step = 1;
        document.getElementById(`${component.name}InvestorTelBtn`)?.focus();
        return store.commit("setSwingMessage", (store.state.account.memberType === "C" ? "대표자 " : "") + "휴대전화 본인 인증을 진행해주세요.");
      }

      if (!state.form.postcode || !state.form.address || !state.form.addressDetail) {
        state.step = 1;
        document.getElementById(`${component.name}InvestorAddress`)?.focus();
        return store.commit("setSwingMessage", "주소를 입력해주세요.");
      }

      if (state.step === 3 && ["11", "23", "12"].includes(state.form.realnumberCerttypeCode)) {
        if (state.eKYCAuthFlag === "Y") {
          state.step = 3;
          return;
        }

        if (store.state.account.memberType === "C") {
          if (!state.files.length && !fileRef.value.value) {
            document.getElementById(`${component.name}File`)?.focus();
            return store.commit("setSwingMessage", "사업자등록증 파일을 첨부해주세요.");
          } else if (!state.form.realnumber) {
            return store.commit("setSwingMessage", "법인등록번호를 입력해주세요.");
          } else if (!state.form.buznumber) {
            return store.commit("setSwingMessage", "사업자등록번호를 입력해주세요.");
          }
        } else {
          const realNum1 = realNum1Ref.value.value;
          const realNum2 = realNum2Ref.value.value;

          if (!realNum1) {
            realNum1Ref.value.focus();
            return store.commit("setSwingMessage", "주민등록번호(혹은 외국인등록번호)를 입력해주세요.");
          } else if (!realNum2) {
            realNum2Ref.value.focus();
            return store.commit("setSwingMessage", "주민등록번호(혹은 외국인등록번호)를 입력해주세요.");
          } else if (!state.files.length && !fileRef.value.value) {
            document.getElementById(`${component.name}File`)?.focus();
            return store.commit("setSwingMessage", "신분증 사본 파일을 첨부해주세요.");
          }

          state.form.realnumber = [realNum1, realNum2].join("");
        }

        // 주민등록번호, 외국인등록번호, 법인등록번호 eKYC API 호출
        if (["11", "23", "12"].includes(state.form.realnumberCerttypeCode)) {
          const file = fileRef.value.files[0];
          const validFormats = ["image/jpeg", "image/jpg", "image/png", "application/pdf", "image/tiff"];

          if (!validFormats.includes(file.type)) {
            document.getElementById(`${component.name}File`)?.focus();
            store.commit("setSwingMessage", "지원되지 않는 파일 형식입니다.");
            return;
          }

          const formData = new FormData();
          formData.append("file", file);
          formData.append("realnumber", state.form.realnumber);

          // 신분증 인증하기
          http.post(`/api/investors/${state.investorSeq}/auth/document-verify`, formData).then((res) => {
            if (res.data.message === "SUCCESS") {
              store.commit("setSwingMessage", "실명 인증되었습니다.");
              state.eKYCAuthFlag = "Y";
              state.step++;
            } else {
              state.alert = res.data.message;
              store.commit("setSwingMessage", res.data.message);
            }
          }).catch(httpError((err) => {
            state.alert = err?.response?.data.message;
            return store.commit("setSwingMessage", err?.response?.data.message);
          }));
        } else {
          // 조합고유번호, 외국인(투자자등록번호)
          // eKYC API를 사용하지 않고 다음 스텝으로
          state.step++;
        }
      }
    };

    const nextStep = () => {
      if (["11", "23", "12"].includes(state.form.realnumberCerttypeCode)) {
        save();
      }

      if (state.step < 4) {
        state.step++;
      }
    };
    const previousStep = () => {
      if (["11", "23", "12"].includes(state.form.realnumberCerttypeCode)) {
        save();
      }

      if (state.step > 1) {
        state.step--;
      }
    };

    const save = async () => {
      const args = lib.getRenewed(state.form);

      try {
        const res = await http.put(`/api/investors/${state.investorSeq}/save`, args);
        if (res.data.body !== "200 OK") {
          store.commit("setSwingMessage", "임시저장 오류");
        }
      } catch (error) {
        store.commit("setSwingMessage", "임시저장 오류");
      }
    };

    return {
      component,
      state,
      save,
      documentVerify,
      nextStep,
      previousStep,
      changeInvestorName,
      submit,
      openNiceAuth,
      resetNiceAuth,
      realNum1Ref,
      realNum2Ref,
      fileRef,
      applyCheck1Ref,
      applyCheck2Ref,
      computedInapplicable,
      computedStatusMessage,
      removeFile,
      setFile
    };
  }
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.mypage";

.my-page-investor-apply {
  .part {
    .subject {
      margin-bottom: $px13;
    }

    .content {
      .form-group {
        > .wrapper {
          position: relative;

          .side {
            position: absolute;
            top: 0;
            right: 0;
            padding-top: $px2;
          }

          .phone {
            &:not(:disabled) {
              border: $px1 solid #ced4da;
              transition: 0.18s;

              &:hover {
                background-color: $colorBackground;
              }
            }
          }
        }

        &.investor-name {
          .change {
            display: flex;
            border-radius: $px4;

            .btn {
              display: none;
            }

            &.changeable {
              input {
                border-color: $colorBorder;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
              }

              .btn {
                border-left-width: 0;
                display: inline-block;
                font-size: $px13;
                width: $px60;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
              }
            }
          }
        }

        &.investor-tel {
          .btn:focus {
            border-color: $colorPointActive;
          }
        }
      }
    }

    &.cert {
      .registration .row {
        position: relative;

        &:after {
          content: "-";
          position: absolute;
          top: $px21;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .file {
        ul li {
          position: relative;

          a {
            display: block;
            border: $px1 solid #ced4da;
            background: #e9ecef;
            border-radius: 0.25rem;
            padding: $px11 $px12;
          }

          &:not(:last-child) {
            margin-bottom: $px10;
          }

          i {
            position: absolute;
            top: 50%;
            right: $px7;
            transform: translateY(-50%);
            cursor: pointer;
            padding: $px5;
            opacity: 0.9;

            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }

    &.investor {
      padding-bottom: $px25;

      .content {
        .paper {
          background: #49545f;
          color: #fff;
          padding: $px10 $px25;
          border-radius: $px4;
          margin-top: $px15;

          .section {
            padding: $px15 0;

            .subject {
              color: rgba(255, 255, 255, 0.58);
              margin-bottom: $px4;
            }

            .value {
              b, span {
                vertical-align: middle;
              }

              button {
                padding: $px8 0;
                margin-top: $px-5;
                float: right;
                width: $px75;
              }
            }
          }
        }
      }
    }

    &.padding {
      padding-bottom: $px20;
    }
  }

  .checklist {
    .subject {
      margin-left: $px-2;

      div {
        margin-top: $px5;
      }
    }

    ul {
      padding-top: $px30;

      li {
        input[type=checkbox] {
          margin-right: $px8;
        }
      }
    }
  }

  .top {
    padding-bottom: $px20;
  }

  .alert {
    padding: $px15;

    div {
      span {
        display: inline-block;
      }
    }
  }

  .actions {
    padding: $px50 0 $px40 0;
    text-align: center;
    position: relative;

    .btn {
      padding: $px19 $px24;

      &.prev {
        margin-right: $px15;
      }
    }
  }

  &.skeleton {
    .alert {
      background: $colorBackground;
      border-color: #eee;

      p, div {
        > span {
          @include skeleton;
        }
      }
    }

    .part {
      .subject {
        > span, b {
          @include skeleton;
        }
      }

      .subject > span {
        @include skeleton;
      }

      .form-group::v-deep {
        select, input, .btn {
          @include skeleton;
        }

        .side > a {
          @include skeleton;
        }

        .address .reset-btn {
          visibility: hidden;
        }
      }
    }

    .guide {
      li {
        @include skeleton;
      }
    }

    .checklist {
      li {
        input {
          display: none;
        }

        span {
          @include skeleton;
        }
      }
    }

    .actions {
      .btn {
        @include skeleton;
      }
    }
  }
}
</style>